import { Injectable } from '@angular/core';
import {StorageKeyEnum} from '../../enum/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private list: Map<string, any> = new Map<string, any>();

  constructor() {
    const entries = Object.entries(StorageKeyEnum);
    for (const [, propertyValue] of entries) {
      const k = StorageService.formatAppStorageKey( propertyValue);
      if (localStorage.getItem(k)){
        this.list.set(k, JSON.parse(localStorage.getItem(k)));
      }
    }
  }

  private static formatAppStorageKey(key: StorageKeyEnum ): string {
    return `storage.${key}`;
  }
  exist(uuid: StorageKeyEnum): boolean {
    return this.list.has(StorageService.formatAppStorageKey( uuid));
  }
  get(uuid: StorageKeyEnum): any {
    return this.list.get(StorageService.formatAppStorageKey( uuid));
  }
  set(uuid: StorageKeyEnum, data: any): StorageService {
    this.list.set(StorageService.formatAppStorageKey( uuid), data);
    localStorage.setItem( StorageService.formatAppStorageKey( uuid), JSON.stringify(data));
    return this;
  }

  remove(uuid: StorageKeyEnum): void {
    this.list.delete(StorageService.formatAppStorageKey(uuid));
    localStorage.removeItem(StorageService.formatAppStorageKey(uuid));
  }
}
