import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OnlyAuthenticatedGuard} from '@shared/guards/only-authenticated.guard';
import {OnlyUnauthenticatedGuard} from '@shared/guards/only-unauthenticated.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/dashboard/dashboard.module').then( m => m.DashboardModule),
    canActivate: [ OnlyAuthenticatedGuard ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./components/auth/auth.module').then( m => m.AuthModule),
    canActivate: [OnlyUnauthenticatedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
