import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StorageService} from '../util-services/storage.service';
import {StorageKeyEnum} from '@enum/storage-key.enum';
import {SwalService} from '../util-services/swal.service';
import {RouterEnum} from '@enum/router.enum';

@Injectable({
  providedIn: 'root'
})
export class OnlyUnauthenticatedGuard implements CanActivate {

  constructor(
    private storageService: StorageService,
    private swalService: SwalService,
    private router: Router
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.storageService.exist(StorageKeyEnum.USER)){
      this.router.navigate([RouterEnum.DASHBOARD]);
      return  false;
    }
    return true;
  }
}
