export const environment = {
   nodeEnv: 'develop',
   production: false,
   api: 'http://localhost/api-profonanpe/public/api',
   urlBaseFiles: 'https://api.miramar.pappstest.com/storage/',
   pagination: {
    pageSizeOptions: [10 , 15, 20, 30]
   },
   mapBoxToken: "pk.eyJ1IjoiZ2Fib2l2IiwiYSI6ImNrdmNsdWw5MzBiZXoydW8yc2hpYmd0NHcifQ.ooD27X_cDFKGlkutoPZDFA"
};
