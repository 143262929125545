export enum RouterEnum {
  DASHBOARD = '/',
  DEPARTMENT_INDEX = '/departments',
  EDUCATION_LEVEL_INDEX = '/education-levels',
  EDUCATION_HEALTH_SERVICES_INDEX = '/health-services',
  PARAMETERS = '/parameters',
  WEATHER_CONDITION_TYPE_INDEX = '/weather-condition-types',
  ANIMALS_GROUPS_INDEX = '/animal-groups',
  ANIMALS_TYPES_INDEX = '/animal-types',
  ANIMALS_GROUPS_SIZE_INDEX = '/animal-group-size',
  ANIMALS_INDEX = '/animals',
  USERS_INDEX = '/users',
  ZONES_INDEX = '/zones',
  OCCUPATION_INDEX = '/occupations',
  ASSOCIATIONS_INDEX = '/associations',
  QUESTION_INDEX = '/questions',
  COVE_INDEX = '/coves',
  HABITATS_INDEX = '/habitats',
  HABITAT_AREAS_INDEX = '/habitat-areas',
  HARMFUL_ALGAL_AREAS_INDEX = '/harmful-algal-areas',
  COLORATIONS_INDEX = '/colorations',
  REPORT_ECONOMY = '/reports/report-economy',
  REPORT_ENVIROMENT = '/reports/report-enviroment',
  PRODUCTION_TAKE_PLACES_INDEX = '/production-take-places',
  MARINE_DEBRIS_INDEX = '/marine-debris',
  MOON_TYPES_INDEX = '/moon-types',
  SITUATION_TYPES_INDEX = '/situation-types',
  CAPTURE_METHODS_INDEX = '/capture-methods',
  SMELL_TYPES_INDEX = '/smell-types',
  POLLUTION_OIL_AREAS_INDEX = '/pollution-oil-areas',
  INDICATOR_REPORTS_INDEX = '/indicator-reports',
  ALERT_COLORATION_INDEX = '/alerts/colorations',
  ALERT_HARMFUL_ALGAL_AREAS_INDEX = '/alerts/harmful-algal-areas',
  ALERT_MARINE_DEBRIS_INDEX = '/alerts/marine-debris',
  LOGIN = '/auth',
}
