import { Injectable } from '@angular/core';
import {SweetAlertResult} from 'sweetalert2';

import Sweet from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  error(title: string, message: string): Promise<SweetAlertResult>{
    return Sweet.fire(title, message, 'error');
  }

  errorMessageWithConfirmed(title: string, message: string): Promise<SweetAlertResult> {
    return  Sweet.fire({
      title,
      icon: 'error',
      html: message,
      confirmButtonText: 'Ok',
      focusConfirm: false,
    });
  }
  confirm(title: string, message: string): Promise<SweetAlertResult> {
    return  Sweet.fire({
      title,
      // icon: 'error',
      html: message,
      confirmButtonText: 'Si',
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: `No`,
    });
  }
  success(title: string = 'Éxito', message: string = 'Realizado'): Promise<SweetAlertResult> {
    return  Sweet.fire({
      title,
      icon: 'success',
      html: message,
      confirmButtonText: 'Ok',
      focusConfirm: false,
    });
  }

  alertMessage(title: string, message: string): Promise<SweetAlertResult> {
    return Sweet.fire({
      title,
      icon: 'warning',
      html: message,
      showCloseButton: true,
      focusConfirm: false,
    });
  }
  catchAndShowError(error: any): void {
    let { message } = error.error;
    if (!message) {
      message = error.message;
    }
    this.error('Error', message);
  }
}
